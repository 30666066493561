// lucide icons
import {
  AlertTriangleIcon,
  ArrowUpFromLine,
  BaggageClaimIcon,
  BellIcon,
  BriefcaseIcon,
  Building2Icon,
  CalendarCheck2,
  CalendarClockIcon,
  CalendarFoldIcon,
  CalendarIcon,
  CalendarMinus,
  CalendarPlus,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronsUpDownIcon,
  Clipboard,
  ClipboardPlus,
  Clock4,
  Copy,
  CopyCheck,
  DatabaseIcon,
  DeleteIcon,
  DoorOpenIcon,
  DownloadIcon,
  EditIcon,
  ExternalLink,
  EyeIcon,
  EyeOffIcon,
  FileArchiveIcon,
  FileAudio2Icon,
  FileIcon,
  FileImageIcon,
  FilePlus2Icon,
  FileSpreadsheetIcon,
  FileTextIcon,
  FileVideo2Icon,
  GaugeCircleIcon,
  GlobeIcon,
  HardHat,
  HelpCircleIcon,
  HomeIcon,
  InfoIcon,
  LinkIcon,
  ListVideoIcon,
  LocateFixedIcon,
  LockIcon,
  LucideIcon,
  MapPin,
  MapPinIcon,
  MenuIcon,
  MoreHorizontalIcon,
  MoreVerticalIcon,
  PaperclipIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  Printer,
  RectangleEllipsis,
  RotateCwIcon,
  SearchIcon,
  SendIcon,
  Settings2Icon,
  ShapesIcon,
  Ship,
  SirenIcon,
  SmartphoneIcon,
  TrashIcon,
  TruckIcon,
  Upload,
  UserSquareIcon,
  Users2Icon,
  UsersIcon,
  Wand2,
  XIcon,
  Gauge,
  EllipsisVertical,
  UserRoundCheck,
  BatteryChargingIcon,
  BatteryLow,
  BatteryMediumIcon,
  BatteryLowIcon,
  BatteryFullIcon,
  BatteryWarningIcon,
  BatteryIcon,
  ActivityIcon
} from 'lucide-react'
// Custom icons
import {
  FilePDFIcon,
  FruitTruckDriverIcon,
  GeopositionIcon,
  GoogleCalendarIcon,
  HistoryEmptyStateIcon,
  LogoutIcon,
  MaintenanceOperatorIcon,
  Map3DIcon,
  OutlookCalendarIcon,
  PermitToWorkIcon,
  SafetyInductionIcon,
  ShipServiceIcon,
  SuspensionsIcon,
  VisitIcon,
  WHOperatorIcon
} from './assets'

export type Icon = LucideIcon

export const Icons = {
  BellIcon,
  ActivityIcon,
  Copy: Copy,
  CopyCheck,
  EyeIcon,
  BatteryChargingIcon,
  BatteryIcon,
  BatteryLowIcon,
  BatteryMediumIcon,
  BatteryFullIcon,
  BatteryWarningIcon,
  GeopositionUpdate: Gauge,
  UserRoundCheck,
  EyeOffIcon,
  RectangleEllipsis,
  Delete: DeleteIcon,
  LoadingCircleIcon: RotateCwIcon,
  CalendarClockIcon,
  DashboardIcon: GaugeCircleIcon,
  FacilityIcon: Building2Icon,
  ModulesIcon: DatabaseIcon,
  NotificationSenderIcon: SendIcon,
  NotifyVisitorsIcon: SirenIcon,
  XIcon,
  MenuThreeBarsIcon: MenuIcon,
  HistoryEmptyStateIcon,
  SearchIcon,
  LogOutIcon: LogoutIcon,
  InductionsIcon: ListVideoIcon,
  Map3DIcon: MapPinIcon,
  GeopositionIcon: LocateFixedIcon,
  VisitsIcon: Users2Icon,
  InfoIcon,
  TeamIcon: ShapesIcon,
  SettingsIcon: Settings2Icon,
  ProfileIcon: UserSquareIcon,
  BaggageIcon: BaggageClaimIcon,
  SpeedIcon: GaugeCircleIcon,
  AltitudeIcon: ArrowUpFromLine,
  PaperclipIcon,
  LinkIcon,
  HomeIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronsUpDownIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  TruckDriverIcon: TruckIcon,
  VisitorIcon: DoorOpenIcon,
  EmployeeIcon: BriefcaseIcon,
  CompanyIcon: BriefcaseIcon,
  ContractorIcon: HardHat,
  PassengerIcon: Ship,
  AlertTriangleIcon,
  HorizontalDotsIcon: MoreHorizontalIcon,
  VerticalDotsIcon: MoreVerticalIcon,
  CalendarIcon,
  Clipboard,
  ClipboardPlus,
  AppIcon: SmartphoneIcon,
  PhoneIcon,
  AllUsers: UsersIcon,
  EditPhotoIcon: PencilIcon,
  AddIcon: PlusIcon,
  CheckIcon,
  FileImageIcon,
  FileTextIcon,
  FileSpreadsheetIcon,
  FileAudio2Icon,
  FilePDFIcon,
  FileArchiveIcon,
  FilePlus2Icon,
  FileVideo2Icon,
  Users: UsersIcon,
  Trash: TrashIcon,
  Edit: EditIcon,
  CalendarEdit: CalendarFoldIcon,
  Download: DownloadIcon,
  External: ExternalLink,
  GlobeIcon: GlobeIcon,
  MagicWand: Wand2,
  Lock: LockIcon,
  MaintenanceOperatorIcon,
  WHOperatorIcon,
  FruitTruckDriverIcon,
  GoogleCalendarIcon,
  OutlookCalendarIcon,
  ShipServiceIcon,
  Modules: {
    VisitIcon,
    GeopositionIcon,
    PermitToWorkIcon,
    SafetyInductionIcon,
    ThreeDMapIcon: Map3DIcon,
    SuspensionsIcon
  },
  TimezoneIcon: MapPin,
  FileIcon,
  HelpCircleIcon,
  PendingSignatureIcon: Clock4,
  SignedSignatureIcon: CheckIcon,
  Print: Printer,
  CalendarPlus,
  Upload,
  Approve: CalendarCheck2,
  Deny: CalendarMinus,
  MenuEllipsisVertical: EllipsisVertical
}
